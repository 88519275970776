<template>
	<div class="_pc-pad-y-30 _pc-pad-x-280" style="background: #f7f7f7;">
		<div class="order-detail-box">
			<div class="detail-head-title">
				<div class="left _b">{{ $t('order_detail.order_number') }}:{{ order_info.ordersn }}</div>
				<div class="right" @click="navBack">
					<img style="width: 16px;" src="~@/assets/image/hangda/fanhui2.png" alt="">
					<span style="margin-left: 7px;">{{ $t('order_detail.return_my_center') }}</span>
				</div>
			</div>
			<div class="info-1">
				<div class="left">
					<div class="li" style="background: #FF8400;">{{ $t('order_detail.send') }}</div>
					<div class="line"></div>
					<div class="li" style="background: #4284DC;">{{ $t('order_detail.receive') }}</div>
				</div>
				<div class="right" style="position: relative;">
					<div class="li">
						<div class="_pc-font-22 _b" style="color: #333;">{{ lang === 'zh_cn' ?order_info.storehouse_name : order_info.storehouse_name_us }}</div>
						<div class="_pc-font-18" style="margin-top: 17px;">
							<span class="_pc-mar-right-15" style="color: #4284DC;">{{ order_info.storehouse_phone }}</span>
							<span style="color: #999999;">{{ order_info.storehouse_province+order_info.storehouse_city+order_info.storehouse_area+order_info.storehouse_address }}</span>
						</div>
					</div>
					<div class="li" style="position: absolute;top: 150px;">
						<div class="_pc-font-22 _b" style="color: #333;">{{ order_info.nickname }}
							<Button v-if="order_info.status < 90 && order_info.status > 0 && agentInfo.is_main" @click="openEdit">{{$t('修改地址')}}</Button>
						</div>
						<div class="_pc-font-18" style="margin-top: 17px;">
							<span class="_pc-mar-right-15" style="color: #4284DC;">{{ order_info.mobile }}</span>
							<span style="color: #999999;">{{ order_info.area+order_info.province+order_info.city+order_info.address }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="info-2 _pc-mar-top-75">
				<div class="li _pc-pad-y-25">
					<div class="left" style="display: flex;">
						<div style="width: 130px;">{{ $t('大包单号') }}</div>
						<div style="flex: 1;">
							<Space wrap>
								<div style="float: left;" v-for="(item,index) in order_info.big_pack_list" :key="index">
									<div style="background-color: #4284DC;padding: 5px;border-radius: 5px;color: #FFFFFF;margin-left: 5px;cursor: pointer;" @click="toTrack(item)">{{ item.tag_no }}</div>
								</div>
							</Space>
						</div>
					</div>
					<div style="font-size: 14px;padding-left: 130px;margin-top: 10px;">{{ $t('点击可查看包裹轨迹') }}</div>
				</div>
				<div class="li _pc-pad-y-25 _flex-row-bet-mid">
					<div class="left" style="width: 50%;">
						<span class="_pc-pad-right-40">{{ $t('创建时间') }}</span>
						<span style="color: #333;">{{ order_info.add_time }}</span>
					</div>
					<div class="right" style="width: 50%;">
						<span class="_pc-pad-right-40">{{ $t('order_detail.status') }}</span>
						<span :style="'color: '+order_info.status_color">{{ order_info.status_name }}</span>
					</div>
				</div>
				<div class="li _pc-pad-y-25 _flex-row-bet-mid">
					<div class="left" style="width: 50%;">
						<span class="_pc-pad-right-40">{{ $t('order_detail.logistics_number') }}</span>
						<span style="color: #333;">{{ order_info.express_sn }}</span>
					</div>
					<div class="right" style="width: 50%;">
						<span class="_pc-pad-right-40">{{ $t('order_detail.route') }}</span>
						<span style="color: #333;" v-if="lang == 'zh_cn'">{{ order_info.express_name }}</span>
						<span style="color: #333;" v-if="lang == 'ru'">{{ order_info.express_name_ru }}</span>
						<span style="color: #333;" v-if="lang == 'en_us'">{{ order_info.express_name_us }}</span>
					</div>
				</div>
				<div class="li _pc-pad-y-25 _flex-row-bet-mid">
					<div class="left" style="width: 50%;">
						<span class="_pc-pad-right-40">{{ $t('打包时间') }}</span>
						<span style="color: #333;">{{ order_info._pack_time }}</span>
					</div>
					<div class="right" style="width: 50%;">
						<span class="_pc-pad-right-40">{{ $t('order_detail.rukucang') }}</span>
						<span style="color: #333;">{{ order_info.storehouse_name }}</span>
					</div>
				</div>
				<div class="li _pc-pad-y-25">
					<div class="left _flex-row-mid">
						<span class="_pc-pad-right-40">{{ $t('打包图片') }}</span>
						<img class="_pc-mar-right-20" v-for="(item,index) in order_info.pack_img" :key="index" style="width: 95px;height: 95px;border-radius: 5px;" :src="item" alt="">
					</div>
				</div>
				<div class="li _pc-pad-y-25">
					<div class="left" style="display: flex;">
						<div style="width: 130px;">{{ $t('包裹单号') }}</div>
						<div style="flex: 1;">
							<!-- <Tag v-for="(item,index) in order_info.package_list" :key="index" @click="onCopy(item.expresssn)" size="large" style="font-size: 24px;cursor: pointer;" color="primary">{{ item.expresssn }}</Tag> -->
							<Space wrap>
								<div style="width: 200px;height: 100px;float: left;" v-for="(item,index) in order_info.package_list" :key="index">
									<div><Button type="primary" size="large" @click="getReportInfo(item.expresssn)">{{ item.expresssn }}</Button></div>
									<img v-viewer v-for="(pic,key) in item.package_imgs" :key="key" style="width: 50px;height: 50px;" :src="pic"/>
								</div>
							</Space>
						</div>
						<!-- <span class="_pc-pad-right-40">{{ $t('包裹单号') }}</span>
						<Tag v-for="(item,index) in order_info.package_list" :key="index" @click="onCopy(item.expresssn)" size="large" style="font-size: 24px;cursor: pointer;" color="primary">{{ item.expresssn }}</Tag> -->
					</div>
				</div>
			</div>
			<div class="info-3 _pc-mar-top-50">
				<div class="title _b">{{ $t('order_detail.package_info') }}</div>
				<div class="main">
					<div class="pack-info-box" v-for="(item,index) in order_info.out_package_list" :key="index">
						<div class="_flex-row-mid">
							<img class="_pc-mar-right-15" style="width: 21px;height: 21px;" src="http://api.kmaonan.com/uploads/attach/2023/06/20230616/d7c6353da508977b456215aaa277b43c.jpg" alt="">
							<span class="_pc-font-22">{{ $t('打包单号') }}：{{ item.pack_no }}</span>
						</div>
						<div class="_pc-font-18 _pc-mar-top-20" style="color: #C81920;">{{ $t('order_detail.status') }}:{{ item.status_name }}</div>
						<div class="_w-max _flex-row-mid _pc-mar-top-20">
							<div class="_pc-w-200">{{ $t('order_detail.long') }}: {{ item.length }}cm</div>
							<div>{{ $t('order_detail.wide') }}: {{item.width}}cm</div>
						</div>
						<div class="_w-max _flex-row-mid _pc-mar-top-15">
							<div class="_pc-w-200">{{ $t('order_detail.height') }}: {{item.height}}cm</div>
							<div>{{ $t('order_detail.weight') }}: {{item.weight}}kg</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Modal v-model="open_edit" footer-hide width="550">
			<div class="from-body">
				<div class="col">
					<div class="name">{{$t('收件人')}}</div>
					<div class="input-box">
						<input type="text" v-model="formData.linkman" :placeholder="$t('请输入')" />
					</div>
				</div>
				<div class="col">
					<div class="name">{{$t('联系电话')}}</div>
					<div class="input-box">
						<input type="text" v-model="formData.mobile" :placeholder="$t('请输入')" />
					</div>
				</div>
				<div class="col">
					<div class="name">{{$t('国家/地区')}}</div>
					<div class="input-box" @click="openCountry">
						<div style="flex: 1;">
							<span :class="formData.area ? '' : '_grey-99'">{{formData.area||$t('请选择')}}</span>
						</div>
						<div @click="openCountry">
							<div class="iconfont2 icon-xiala" style="color: #999;"></div>
						</div>
						<div class="select-address-container" v-if="open_country">
							<div class="select-item" v-for="(item,index) in country_list" :key="index" @click.stop="getCountrys(item)">{{item.address_name}}</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="name">{{$t('省/州')}}</div>
					<div class="input-box" @click="openProvince">
						<div style="flex: 1;">
							<span :class="formData.province ? '' : '_grey-99'">{{formData.province||$t('请选择')}}</span>
						</div>
						<div @click="openProvince">
							<div class="iconfont2 icon-xiala" style="color: #999;"></div>
						</div>
						<div class="select-address-container" v-if="open_province">
							<div class="select-item" v-for="(item,index) in province_list" :key="index" @click.stop="getProvinces(item)">{{item.label}}</div>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="name">{{$t('城市')}}</div>
					<div class="input-box">
						<input type="text" v-model="formData.city" :placeholder="$t('请输入')" />
					</div>
				</div>
				<div class="col">
					<div class="name">{{$t('邮编')}}</div>
					<div class="input-box">
						<input type="text" v-model="formData.zip_code" :placeholder="$t('请输入')" />
					</div>
				</div>
				<div class="col grid-column-max-span">
					<div class="name">{{$t('详细地址')}}</div>
					<div class="input-box">
						<textarea style="width: 100%;min-height: 5rem;" v-model="formData.address" :placeholder="$t('请输入')"></textarea>
					</div>
				</div>
			</div>
			<div class="from-sub-btn">
				<Button @click="addressSubmit" long type="primary">{{$t('修改地址')}}</Button>
			</div>
		</Modal>
		<!-- 包裹详情 -->
		<Modal v-model="open_modal" footer-hide :mask-closable="false" width="800">
			<Form label-position="left" :label-width="120" class="fontsize">
				<Row>
					<Col span="12">
						<FormItem :label="$t('包裹单号')"><span style="font-size: 16px;">{{ reportInfo.expresssn }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('包裹重量')"><span style="font-size: 16px;">{{ reportInfo.weight}}kg</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('长宽高')"><span style="font-size: 16px;">{{ reportInfo.length }}cm*{{reportInfo.width}}cm*{{reportInfo.height}}cm</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('报备时间')"><span style="font-size: 16px;">{{ reportInfo.report_time }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('入库时间')"><span style="font-size: 16px;">{{ reportInfo.enter_time }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('申报价值')"><span style="font-size: 16px;">{{ reportInfo.goods_money }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('物品类型')"><span style="font-size: 16px;">{{ reportInfo.goods_name }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('包裹状态')"><span style="font-size: 16px;">{{ reportInfo.status_name }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('包裹类型')">
							<span style="font-size: 16px;" v-if="lang == 'zh_cn'">{{ reportInfo.cate_name_zh }}</span>
							<span style="font-size: 16px;" v-if="lang == 'ru'">{{ reportInfo.cate_name_ru }}</span>
							<span style="font-size: 16px;" v-if="lang == 'en_us'">{{ reportInfo.cate_name_us }}</span>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('到付金额')"><span style="font-size: 16px;">{{ reportInfo.collect_payment }}RMB</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('到付金额')"><span style="font-size: 16px;">{{ reportInfo.price }}USD</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('是否超大包裹')"><span style="font-size: 16px;">{{ reportInfo.is_big_report?$t('yes'):$t('no') }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('木架费')"><span style="font-size: 16px;">{{ reportInfo.reinforce_free }}</span></FormItem>
					</Col>
					<Col span="12">
						<FormItem :label="$t('入库图片')">
							<div style="float: left;" v-viewer v-for="(val,indexs) in reportInfo.package_imgs" :key="indexs">
								<img style="width: 40px;height: 40px;" :src="val">
							</div>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</Modal>
	</div>
</template>

<script>
	import { userOrderInfo, getReportInfo, getCountry, editShippingAddress, bannerList, getProvinceOption } from '@/api/jiyun';
	export default {
		data() {
			return {
				// metaInfo
				title: this.$t('page_title.order_detail'),
				orderId: 0,
				order_info: {},
				lang: 'zh_cn',
				open_modal: false,
				reportInfo: {},
				open_edit: false,
				formData: {
					linkman: '',
					mobile: '',
					area_id: '',
					area: '',
					province: '',
					city: '',
					address: '',
					zip_code: '',
				},
				country_list: [],
				open_country: false,
				open_province: false,
				is_check: false,
				agentInfo: {},
				province_list: [],
			}
		},
		metaInfo() {
			return {
				title: this.title, // set a title
			};
		},
		mounted(){
			this.orderId = this.$route.query.orderId;
			this.lang = localStorage.getItem('think-lang') || 'zh_cn';
			this.orderInfo();
			this.getCountry();
			this.bannerList();
		},
		methods: {
			// 物流轨迹
			toTrack(row){
				if(row.pack_date) row.pack_date = row.pack_date.slice(0,10);
				this.$router.push({
					path: '/search',
					query: {
						expresssn: row.tag_no + '/' + row.pack_date
					}
				})
			},
			// 获取城市列表
			getProvince(){
				let param = {
					pid: this.formData.area_id
				}
				getProvinceOption(param).then((res)=>{
					this.province_list = res.data;
					if(this.province_list.length <= 0){
						this.$Message.info(this.$t('请重新选择国家或地区'));
						this.formData.area_id = 0;
						this.formData.area = '';
						this.formData.province = '';
					}
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 选择城市
			getProvinces(province){
				if(!this.formData.area_id) return this.$Message.error(this.$t('请先选择国家或地区'));
				this.open_province = false;
				this.formData.province = province.label;
			},
			// 省州下拉
			openProvince(){
				if(!this.formData.area_id) return this.$Message.info(this.$t('请先选择国家或地区'));
				this.open_province = !this.open_province;
				this.open_country = false;
			},
			// 打开国家下拉
			openCountry(){
				this.open_country = !this.open_country;
				this.open_province = false;
			},
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agentInfo = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 提交
			addressSubmit(){
				if(this.is_check) return;
				this.is_check = true;
				this.formData.order_id = this.order_info.id;
				editShippingAddress(this.formData).then((res)=>{
					this.is_check = false;
					this.open_edit = false;
					this.$Message.success(res.msg);
					this.orderInfo();
				}).catch((err)=>{
					this.is_check = false;
					this.$Message.error(err.msg);
				})
			},
			// 编辑地址
			openEdit(){
				this.formData.linkman = this.order_info.linkman;
				this.formData.mobile = this.order_info.mobile;
				this.formData.area_id = this.order_info.area_id;
				this.formData.area = this.order_info.area;
				this.formData.province = this.order_info.province;
				if(this.formData.area_id) {
					let country = this.country_list.find(item => item.id == this.formData.area_id);
					if(!country){
						this.$Message.info(this.$t('请先选择国家或地区'));
						this.formData.area_id = 0;
						this.formData.area = '';
						this.formData.province = '';
					}else{
						this.formData.area_id = country.id;
						this.formData.area = country.address_name;
						this.getProvince();
					}
				}
				
				this.formData.city = this.order_info.city;
				this.formData.address = this.order_info.address;
				this.formData.zip_code = this.order_info.zip_code;
				this.open_edit = true;
			},
			// 选择国家
			getCountrys(country) {
				console.log(country)
				this.open_country = false;
				this.formData.area_id = country.id;
				this.formData.area = country.address_name;
				this.formData.province = '';
				this.formData.city = '';
				this.formData.address = '';
				this.getProvince();
			},
			// 获取国家列表
			getCountry(){
				getCountry().then((res)=>{
					this.country_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 包裹详情
			getReportInfo(expresssn){
				let param = {
					expresssn: expresssn
				}
				getReportInfo(param).then((res)=>{
					this.reportInfo = res.data;
					this.open_modal = true;
				}).catch((err)=>{
					this.$Message.error(err.msg);
				})
			},
			// 订单详情
			orderInfo(){
				let param = {
					order_id: this.orderId,
					domain_url: window.location.origin
				}
				userOrderInfo(param).then((res)=>{
					this.order_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			navBack() {
				this.$router.back()
			},
			// 复制
			onCopy(text){
				this.$copyText(text).then(function(e){
					this.$Message.success(this.$t('复制成功'))
				}),function(e){
					this.$Message.error(this.$t('复制失败'))
				}
			}
		}
	}
</script>


<style scoped>
	input {
		outline: none;
		background: #F7F7F7;
	}
	textarea {
		outline: none;
		background: #F7F7F7;
	}
	.grid-column-max-span{
		grid-column: 1 / -1;
	}
	.from-body {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 3.125rem;
		grid-row-gap: 1rem;
		padding: 1.625rem 0.9375rem;
		font-size: 0.9375rem;
	}
	.from-body .col{
		display: flex;
		flex-direction: column;
		row-gap: 0.9375rem;
		width: 100%;
	}
	.select-address-container {
		position: absolute;
		left: 0;
		top: 110%;
		background-color: #fff;
		border-radius: 0.5rem;
		width: 100%;
		min-height:  2.8125rem;
		max-height: calc(2.8125rem * 6);
		border: 1px solid #D6D6D6;
		z-index: 2;
		box-shadow: -1px 7px 9px 4px rgba(0, 0, 0, 0.3);
		overflow: hidden;
	}
	.select-address-container .select-item {
		width: 100%;
		padding: 0rem 0.9375rem;
		display: flex;
		align-items: center;
		height: 2.8125rem;
		border-bottom: 1px solid #f4f4f4;
	}
	/* .mb-main-box .mb-main-box-li .mb-li .input-box .select-address-container .select-item:hover {
		background-color: rgba(66, 132, 220, 0.3);
		color: #999;
	} */
	.select-address-container .select-item:last-child {border-bottom: none;}
	.from-body .col .input-box {
		width: 100%;
		border-radius: 0.375rem;
		background: #F7F7F7;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.9375rem 0.9375rem;
		position: relative;
	}
	.order-detail-box {
		width: 100%;
		border-radius: 10px;
		padding: 55px;
		background: #fff;
	}

	.order-detail-box .detail-head-title {
		color: #333;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.order-detail-box .detail-head-title .left {
		font-size: 36px;
	}

	.order-detail-box .detail-head-title .right {
		margin-top: 9px;
		width: 180px;
		border-radius: 8px;
		padding: 15px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border: 1px solid #4284DC;
		color: #4284DC;
	}

	.order-detail-box .detail-head-title .right:hover {
		cursor: pointer;
		opacity: 0.4;
	}

	.order-detail-box .info-1 {
		display: flex;
		margin-top: 30px;
	}

	.order-detail-box .info-1 .left {
		margin-right: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.order-detail-box .info-1 .left .li {
		/* width: 40px; */
		height: 40px;
		border-radius: 7px;
		color: #fff;
		padding: 10px;
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.order-detail-box .info-1 .left .line {
		width: 1px;
		height: 108px;
		background: #FFFFFF;
		border: 1px dashed #999999;
		opacity: 0.6;
	}

	.order-detail-box .info-2 .li {
		border-bottom: 1px solid rgba(153, 153, 153, 0.2);
		font-size: 22px;
		color: #999999;
	}

	.order-detail-box .info-2 .li:last-child {
		border-bottom: none;
	}

	.order-detail-box .info-3 {
		width: 100%;
		color: #333;
	}

	.order-detail-box .info-3 .title {
		font-size: 36px;
	}

	.order-detail-box .info-3 .main {
		width: 100%;
		margin-top: 33px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.order-detail-box .info-3 .main .pack-info-box {
		width: 580px;
		/* height: 195px; */
		background-image: url(../../assets/image/hangda/detailOrderInfobg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
		padding: 40px 25px;
		color: #333;
		flex-shrink: 0;
		margin-bottom: 20px;
		/* border: 1px solid #000; */
	}
	.fontsize /deep/ .ivu-form-item-label {
		font-size: 16px;
	}
</style>